import * as React from "react";
import { Navbar } from "./../components/navbar.js";
import { storage } from "./../firebase.js";
import { db } from "./../firebase.js";
import worker from "./../assets/ndtWorker.jpg";
import { useEffect } from "react";
import { uuidv4 } from "@firebase/util";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Footer } from "./../components/footer.js";

export const Contactus = () => {
  const [image, setImage] = React.useState(null);
  const [imglist, setImglist] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const imageListRef = ref(storage, "img/");
  const [resuemeUrl, setResumeUrl] = React.useState(null);
  const [data, setData] = React.useState({});

  function HandleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(value);
    console.log(name);
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    console.log(data, "31");
  }

  useEffect(() => {
    console.log(data);
  }, [image]); // This effect will trigger whenever the 'data' state changes

  // Upload file
  const uploadFile = () => {
    if (image == null) return;

    const imageRef = ref(storage, `img/${image.name + uuidv4()}`);
    const uploadFile = uploadBytesResumable(imageRef, image);
    uploadFile.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (err) => {},
      () => {
        setProgress(0);
        getDownloadURL(uploadFile.snapshot.ref).then((url) => {
          setImglist((prev) => [
            ...prev,
            { url: url, ref: uploadFile.snapshot.ref },
            setData((prev) => {
              return { ...prev, resuemeUrl: url };
            }),
          ]);
        });
      }
    );
    console.log(data);
  };

  // Delete file
  const deleteHandel = (ref, url) => {
    deleteObject(ref).then((res) => {
      setImglist(imglist.filter((img) => img.url !== url));
      alert("Successfully deleted");
    });
  };

  // Get data
  useEffect(() => {
    const getData = () => {
      listAll(imageListRef).then((res) => {
        res.items.map((item) => {
          getDownloadURL(item).then((url) => {
            setImglist((prev) => [...prev, { url: url, ref: item }]);
          });
        });
      });
    };
    getData();
  }, []);

  const [file, setFile] = React.useState(null);
  const [preview, setPreviewUrl] = React.useState(null);
  const filePickerRef = React.useRef();

  function pickeResumeHandler() {
    let pickedFile;
    if (
      filePickerRef.current.files &&
      filePickerRef.current.files.length === 1
    ) {
      pickedFile = filePickerRef.current.files[0];
      setFile(pickedFile);
    }
  }

  function pickedFileHandler() {
    filePickerRef.current.click();
  }

  const fileHndler = (e) => {
    e.preventDefault();
    console.log(e.target);
    const file = e.target[0].files[0];
    console.log(file);
  };

  const submitData = async (event) => {
    event.preventDefault();
    const res = fetch(
      "https://bharatndt-backend-default-rtdb.firebaseio.com/users.json",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res) {
      console.log(data, "58");
      console.log("DATA");
    } else {
      console.log("NO DATA");
    }
  };

  return (
    <div>
      <Navbar />
      <div className=" xl:pt-20 container w-11/12 lg:w-10/12 mx-auto">
        <div className="w-full h-full lg:flex items-start justify-center xl:py-20 py-24 px-4">
          <div className="lg:w-1/2 xl:mr-12 lg:mr-6">
            <img
              tabIndex={0}
              src={worker}
              alt="image of a dog with heart"
              className="w-full h-full"
            />
            <div className="flex flex-wrap items-center mt-8">
              <p
                role="contentinfo"
                className="mt-4 text-base leading-6 text-gray-600 lg:block hidden"
              >
                BharatNDT specializes in inspection, repair, maintenance, and
                calibration services. Leveraging a wide range of offshore,
                marine, and petro-chemical inspection products, along with
                expert technical knowledge from our global alliance and joint
                venture companies, we are uniquely equipped to fulfill all your
                inspection needs. Rest assured, all the necessary information to
                make well-informed decisions about your inspection services can
                be found on this website. For any further inquiries or pricing
                details, please don't hesitate to reach out to us.{" "}
              </p>
            </div>
          </div>
          <div className="lg:w-1/3 xl:pl-12 lg:pl-6 lg:pt-0 pt-4">
            <h1
              aria-label="Get in touch"
              role="heading"
              className="text-3xl font-bold   text-gray-900"
            >
              Get in touch
            </h1>
            <div
              arial-label="Address"
              className="mr-6 border md:w-auto w-full rounded border-gray-200 py-6 pr-9"
            >
              <p className="text-base font-semibold leading-4 text-gray-900">
                Navi Mumbai
              </p>
              <p className="text-base leading-4 mt-4 text-gray-600">Address</p>
              <p className="text-base leading-4 mt-2 text-gray-600 pt-2">
                Block No B7, 3rd Floor, Silver CHS,.Panvel, Raigad, MH-401206
              </p>
            </div>
            <p
              role="contentinfo"
              className="mt-4 text-base leading-6 text-gray-600 lg:hidden"
            >
              It is a long established fact that a reader will be distracted by
              the readable{" "}
            </p>
            <div className="xl:flex items-center justify-between mt-10">
              <div>
                <p className="text-base font-medium leading-4 mb-4 text-gray-800">
                  First Name
                </p>
                <input
                  type="name"
                  aria-label="Please input first name"
                  className="xl:w-48 w-full p-3 text-base leading-none text-gray-500 bg-gray-100 rounded placeholder-gray-500"
                  placeholder="eg. William"
                  name="firstName"
                  value={data.name}
                  onChange={HandleChange}
                />
              </div>
              <div className="xl:ml-6 xl:mt-0 mt-4">
                <p className="text-base font-medium leading-4 mb-4 text-gray-800">
                  Last Name
                </p>
                <input
                  type="name"
                  aria-label="Please input Last name"
                  className="xl:w-48 w-full p-3 text-base leading-none text-gray-500 bg-gray-100 rounded placeholder-gray-500"
                  placeholder="eg. Smith"
                  name="lastName"
                  value={data.lastName}
                  onChange={HandleChange}
                />
              </div>
            </div>
            <div className="mt-6">
              <p className="text-base font-medium leading-4 mb-4 text-gray-800">
                Email Address
              </p>
              <input
                type="email"
                aria-label="Please enter email"
                className="w-full p-3 text-base leading-none text-gray-500 bg-gray-100 rounded placeholder-gray-500"
                placeholder="eg. johndoe@gmail.com"
                name="email"
                value={data.email}
                onChange={HandleChange}
              />
            </div>
            <div className="mt-6">
              <p className="text-base font-medium leading-4 mb-4 text-gray-800">
                Message{" "}
              </p>
              <textarea
                type="text"
                aria-label="Please leave comments"
                className="w-full resize-none h-40 p-3 text-base leading-none text-gray-500 bg-gray-100 rounded placeholder-gray-500"
                // defaultValue={""}
                name="message"
                value={data.message}
                onChange={HandleChange}
              />
            </div>
            <div className="mt-6 ">
              <p className="text-base font-medium leading-4 mb-4 text-gray-800 flex-col">
                Upload Resume{" "}
              </p>
              <div className="flex flex-row">
                <input
                  type="file"
                  className="form-control"
                  id="img-upload"
                  accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(event) => {
                    const selectedFile = event.target.files[0];
                    if (selectedFile) {
                      const allowedTypes = [
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ];
                      if (allowedTypes.includes(selectedFile.type)) {
                        // File type is valid (PDF or Word), process the file here
                        setImage(selectedFile);
                      } else {
                        // Invalid file type, show an error message or take appropriate action
                        window.alert("Please select word or pdf Files");
                        setImage(null);
                      }
                    }
                  }}
                />
                <button className="btn btn-success mx-3" onClick={uploadFile}>
                  Upload
                </button>
              </div>
            </div>
            <button
              role="button"
              arial-label="send message "
              onClick={submitData}
              className="focus:outline-none focus:ring-2 text-white-100 focus:ring-offset-2 focus:ring-indigo-700 hover:bg-indigo-600 text-base font-semibold leading-4 py-6 px-8 text-white bg-indigo-700 rounded mt-12"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;

import { initializeApp,firebase } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import 'firebase/firestore';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDKDDXLHdMcCTI7SE0_p-cXMNfz0m5dyMY",
  authDomain: "bharatndt-backend.firebaseapp.com",
  projectId: "bharatndt-backend",
  storageBucket: "bharatndt-backend.appspot.com",
  messagingSenderId: "368497383896",
  appId: "1:368497383896:web:0ab9ae3bf120a187933aad",
  measurementId: "G-N7CT8TVQ0B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
import * as React from "react";
import { Navbar } from "./../components/navbar.js";
import { Hero } from "./../components/hero.js";
import { Services } from "./../components/Services.js";
import {Footer} from "./../components/footer.js"
import { Stat } from "./../components/stat.js";

export default function Tailwindcard() {
  return (
    <div>
      <Navbar />
      <div className="bg-gray-100">
        <div className="container w-11/12 lg:w-10/12 mx-auto">
          <Hero />
          <Services />
          <Stat />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export { Tailwindcard };

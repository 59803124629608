import * as React from "react";
import ultrasonic from "./../assets/icons/Screenshot 2023-08-01 165922.png";
import loadtesting from "./../assets/icons/icons8-crane-64.png";

export const Services = () => {
  return (
    <div className="mx-auto bg-white dark:bg-gray-800  rounded w-full pt-10 xl:pt0">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl lg:text-5xl font-semibold text-gray-800 text-center xl:pt-14">
          Courses we offer
        </h1>
        <p className="sm:w-9/12 lg:w-full text-base leading-6 text-gray-600 mt-4 text-center">
          We deal in large variety of courses. We have a team of experts who are
          well versed in their respective fields.
        </p>
      </div>
      <div className="py-10 px-6 flex flex-wrap justify-between">
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer sm:flex">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="100"
                height="100"
                className="h-full w-full object-cover rounded"
                src="https://img.icons8.com/ios-filled/100/protect-from-magnetic-field.png"
                alt="protect-from-magnetic-field"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Magnetic Particle Testing
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="100"
                height="100"
                className="h-full w-full object-cover rounded"
                src="https://img.icons8.com/ios-filled/100/deodorant-spray.png"
                alt="deodorant-spray"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Dye Penetrant Testing
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                src={ultrasonic}
                alt
                className="h-full w-full object-cover rounded"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Ultrasonic Testing
            </p>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              for Flaw Detection
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                src={loadtesting}
                alt
                className="h-full w-full object-cover rounded"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Load Testing
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="100"
                height="100"
                src="https://img.icons8.com/ios/100/detective.png"
                className="h-full w-full object-cover rounded"
                alt="detective"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Visual Inspection
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="100"
                height="100"
                src="https://img.icons8.com/ios-filled/100/inspection.png"
                className="h-full w-full object-cover rounded"
                alt="inspection"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              BHA / Tool Inspection
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="80"
                height="80"
                src="https://img.icons8.com/dotty/80/pressure.png"
                className="h-full w-full object-cover rounded"
                alt="pressure"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Pressure Test & Witness
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="96"
                height="96"
                src="https://img.icons8.com/windows/96/group2.png"
                alt="group2"
                className="h-full w-full object-cover rounded"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Third Party Inspection
            </p>
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="64"
                height="64"
                src="https://img.icons8.com/external-itim2101-lineal-itim2101/64/external-electrician-male-occupation-avatar-itim2101-lineal-itim2101.png"
                alt="external-electrician-male-occupation-avatar-itim2101-lineal-itim2101"
                className="h-full w-full object-cover rounded"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Man Power Services
            </p>
            {/* <p className="text-xs text-gray-600 dark:text-gray-400">
              Man Power Services
            </p> */}
          </div>
        </div>
        <div className="xl:w-1/5 sm:w-2/5 w-full mb-12 cursor-pointer">
          <div className="flex flex-col items-center">
            <div className="mb-3 h-20 w-20">
              <img
                width="64"
                height="64"
                className="h-full w-full object-cover rounded"
                src="https://img.icons8.com/wired/64/water.png"
                alt="water"
              />
            </div>
            <p className="text-sm mb-1 text-gray-800 dark:text-gray-100">
              Water Bag Testing
            </p>
            {/* <p className="text-xs text-gray-600 dark:text-gray-400">
              Water Bag Testing
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

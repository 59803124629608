import "./App.css";
import "./App.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Tailwindcard } from "./components/card.js";
// import { Cardetail } from "./components/car-details.js";
import { Trainings } from "./components/trainings.js";
import { Vacanc } from "./components/vacancies.js";
import { Aboutus } from "./components/about.js";
import { Contactus } from "./components/contactus.js";
import React  from "react";

function App() {
  return (
    <Router>
      <Route exact path="/" component={Tailwindcard} />
      <Route exact path="/trainings" component={Trainings} />
      {/* <Route exact path="/car-details/:id" component={Cardetail} /> */}
      <Route exact path="/vacancies" component={Vacanc} />
      <Route exact path="/about-us" component={Aboutus} />
      <Route exact path="/contact-us" component={Contactus} />
    </Router>
  );
}

export default App;

import * as React from "react";
import { Navbar } from "./../components/navbar.js";
import { Footer } from "./../components/footer.js";

export default function Trainings() {
  const [showSpecializedInspection, setShowSpecializedInspection] =
    React.useState(false);
  const [tubularInspectionDivision, setTubularInspectionDivision] =
    React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [tubularInspectionDivision, showSpecializedInspection]);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setTubularInspectionDivision(selectedOption === 'tubular');
    setShowSpecializedInspection(selectedOption === 'specialized');
  };


  return (
    <div>
      <Navbar />
      <div class="container w-11/12 lg:w-10/12 mx-auto">
        <div class="bg-white dark:bg-gray-800 flex flex-col items-start justify-between pt-28 sm:pt-8 px-5 sm:px-10 rounded-t">
          <div class="mb-4 xl:mb-0">
            <h2
              tabindex="0"
              class="focus:outline-none text-gray-800 dark:text-gray-100 text-lg font-bold"
            >
              Job Postings
            </h2>
          </div>
          <div class="mb-4 lg:hidden w-full relative">
            <div class="absolute inset-0 m-auto mr-4 z-0 w-6 h-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-selector"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#a0aec0"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="8 9 12 5 16 9" />
                <polyline points="16 15 12 19 8 15" />
              </svg>
            </div>
            <select
              aria-label="Selected tab"
              class="form-select block w-full p-3 border border-gray-300 dark:border-gray-700 rounded text-gray-600 dark:text-gray-400 appearance-none bg-transparent relative z-10"
              onChange={handleSelectChange}

            >
              <option
                class="text-sm text-indigo-700"
                value="tubular"
              >
                Tubular Inspection Division
              </option>
              <option
                class="text-sm text-gray-600 dark:text-gray-400"
                value="specialized" 
              >
                Specialized Inspection Division
              </option>
            </select>
          </div>
          <ul class="hidden lg:flex items-center mt-0 sm:mt-5">
            <li
              tabindex="0"
              className={`${
                tubularInspectionDivision
                  ? "border-indigo-700 focus:text-indigo-700 border-b-2 focus:border-indigo-600 "
                  : ""
              } list-none text-gray-500 dark:text-gray-400 text-sm mr-4 sm:mr-8 md:mr-8 lg:mr-8 xl:mr-8 pb-4 cursor-pointer hover:text-indigo-700`}
              onClick={() => {
                setTubularInspectionDivision(true);
                setShowSpecializedInspection(false);
              }}
            >
              Tubular Inspection Division
            </li>
            <li
              tabindex="0"
              className={`${
                showSpecializedInspection
                  ? "border-indigo-700 focus:text-indigo-700 border-b-2 focus:border-indigo-600 "
                  : ""
              } list-none text-gray-500 dark:text-gray-400 text-sm mr-4 sm:mr-8 md:mr-8 lg:mr-8 xl:mr-8 pb-4 cursor-pointer hover:text-indigo-700`}
              onClick={() => {
                setShowSpecializedInspection(true);
                setTubularInspectionDivision(false);
              }}
            >
              Specialized Inspection Division
            </li>
          </ul>
        </div>
      </div>
      {tubularInspectionDivision && (
        <div className="container w-11/12 lg:w-10/12 mx-auto">
          <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="xl:w-1/2">
                <p className="mb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
                  Tubular Inspection Division
                </p>
                <p className="mb-2 text-xs font-medium text-gray-800 dark:text-gray-100">
                  Bharat NDT can provide a full range of pipe inspection on
                  sizes ranging from 2-3/8" to 6-5/8" while following customer's
                  specifications and edition of DS-1 or API guidelines. We have
                  various types EMI Units, conventional ones as well as dual
                  function unit with wall monitoring. Drill pipe Inspection
                  service include:
                </p>
                <ul>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Visual Body & Thread Inspection.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Thread Gauging.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Electromagnetic Inspection (EMI) With Continous Wall-loss
                    Mesaurement.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Wet And Dry Magnetic Particle Inspection.
                  </li>
                  <li className="mb-2text-xs text-gray-600 dark:text-gray-400">
                    - UT Thickness Gauging.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - End Area Inspection.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Ultrasonic Inspection On End Area.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Dimensional Inspection.
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    We also can provide refacing, straightening and ID &OD
                    cleaning services
                  </li>
                </ul>
              </div>
              {/* <div className="xl:w-1/2">
                <div className="w-3/12 flex flex-col items-end justify-between">
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    9am - 10am
                  </p>
                  <div className="bg-gray-200 h-6 w-20 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                    <span className="text-xs text-gray-600 dark:text-gray-400 font-normal">
                      Meeting
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
                  BHA / Tools Inspection
                </p>
                <p className="mb-2 text-xs font-medium text-gray-800 dark:text-gray-100">
                  BHA Inspections are carried out as per latest edition of API
                  &DS-1 (3-5) guidelines. BHA inspection services include:
                </p>
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Visual Inspection
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Thread Gauging
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Wet And Dry Magnetic Particle Inspection
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Dimensional Inspection
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    Optional service of refacing if required can be provided
                  </li>
                </ul>
              </div>
              {/* <div className="w-3/12 flex flex-col items-end justify-between">
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  9am - 10am
                </p>
                <div className="bg-red-200 h-6 w-20 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                  <span className="text-xs text-red-500 font-normal">
                    Urgent
                  </span>
                </div>
              </div> */}
            </div>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
                  Casing / Tubing Inspection
                </p>
                <p className="mb-2 text-xs font-medium text-gray-800 dark:text-gray-100">
                  Inspection of used casing/tubings are carried out in
                  accordance with API Spec 5CT.General scope shall include:
                </p>
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - OD Blasting
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - ID Cleaning
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Spot Wall Thickness Measurements
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    -Grade Sorting
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    -Emi On Body (Tubing )
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Visual Thread Inspection
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Full Length Drifting
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Black Light Inspections
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Hydraulic Pressure Testing
                  </li>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Bucking ServicesF
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {showSpecializedInspection && (
        <div className="container w-11/12 lg:w-10/12 mx-auto">
          <div className="xl:px-8 lg:px-8 md:px-8 px-4 py-6 bg-white dark:bg-gray-800">
            <p className="mb-2 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Spectiallized Inspection Division
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="xl:w-1/2">
                <p className="mb-2 text-xs font-medium text-gray-800 dark:text-gray-100">
                  NDT Inspection - MP|/LPT/UT/RT
                </p>
                <ul>
                  <li className="mb-2 text-xs text-gray-600 dark:text-gray-400">
                    - Bharat-NDT has a proven track record in the field of Non
                    Destructive Testing (NDT). We have multidisciplinary
                    inspectorsfor performing inspectionusing differentN D
                    techniqueslike MP/LPT/UT/RT depending onthe requirement. Al
                    our NDT inspection personnel are trained to internationally
                    recognized standards with ASNT accreditation.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Lifting Gears Surveys-LOLER/DNL/BSDV
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Tubester can inspect, test and certify al types of
                    liftingappliancesonlocation. We area memberofLEA ni the UK
                    andfollow the latest LOLER guidelines. These
                    surveysprovideacomprehensive register and database of all
                    hoisting and lifting equipments on adrillingrigandincludes
                    both fixed andloose lifting gears. Al the equipments are
                    inspected to latest standards of LOLER / DNL / BSand
                    manufacturing guidelineswherever applicable.
                  </li>
                </ul>
              </div>
              {/* <div className="w-3/12 flex flex-col items-end justify-between">
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  9am - 10am
                </p>
                <div className="bg-red-200 h-6 w-20 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                  <span className="text-xs text-red-500 font-normal">
                    Urgent
                  </span>
                </div>
              </div> */}
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Proof Load Testing of padeyes/cargo handling
              equipments/containers
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Load Testing is carried out by competent inspectors who
                    are competent as per local regulation or qualified
                    byLEE/NSL. In addition they are certified toASNT Level I.
                    Tubestar hasinhouse test facility withtensile test bedsfor
                    pull testing of loose lifting equipmentslike slings,
                    shackles etc upto 100 Tns. We have customized hydraulic
                    jacking units toloadtest pad eyes offshore upto 30T.
                    Hydraulic jacking unitis mounted onthe pad eyesand load
                    tested at required proof loads.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Crane Load Testing/Inspection
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - BharatNDT experienced technicians conduct the crane
                    inspection in accordance with API RD 2D standardand provide
                    recommendation forrepair orfurtherinspection. Statica n
                    dDynamic loadtesting are performed as per requirement and
                    Tubestar has mobilize calibrated loadcellsa n d waterbagsa t
                    short notice to carry out Crane load testing.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Derrick Survey as per API RP 4G
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Bharat NDT completed numerous inspections for Derrick as
                    per API RP 4G guidelines - which is the Recommended Practice
                    for Use and Procedures for Inspection, Maintenance, and
                    Repair of Drilling and Well ServicingStructures. Qualified
                    personnel will carry out Derrickinspection (Category III)
                    survey in line with APIRP4G.Inspection focuses onloadbearing
                    components and members todeterminet h econditionof
                    mast/derrick and substructure. Acomprehensive report as per
                    the template of API RP 4G is prepared and submitted to
                    client with recommendations. Rope access technicians
                    complement the inspector for Derrick boltchecksetc.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Derrick Survey as per API RP 4G
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Bharat NDT completed numerous inspections for Derrick as
                    per API RP 4G guidelines - which is the Recommended Practice
                    for Use and Procedures for Inspection, Maintenance, and
                    Repair of Drilling and Well ServicingStructures. Qualified
                    personnel will carry out Derrickinspection (Category III)
                    survey in line with APIRP4G.Inspection focuses onloadbearing
                    components and members todeterminet h econditionof
                    mast/derrick and substructure. Acomprehensive report as per
                    the template of API RP 4G is prepared and submitted to
                    client with recommendations. Rope access technicians
                    complement the inspector for Derrick boltchecksetc.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Dropped Object Survey (DROPS)
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Tubesatr nac hepl prevent apoetnaitl dropped obejct
                    becoming another unwcomele sayeft statistie yb conduncitg
                    detailed dropped obejct suvrey of hazardous areas.Tubestar
                    provides qualified nispeoctsr to conduct athorough dropped
                    object survey of het derick and other areas sa required. All
                    het arehacwihs earot be inspected aer divided under diferent
                    zones and aer surveyed sa per Clienst' Droped obejct pocily.
                    Allhte findings aer recordedni hte reportwhti photographic
                    evidence and corective acoitns ofrthe same.
                    Reportsidividedintowtoparts,onesitheInspectionSurveywhcihincludes
                    al observations and another is the Failed items list which
                    coversthe items which failed ni the survey.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Dropped Object Survey (DROPS)
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Tubesatr nac hepl prevent apoetnaitl dropped obejct
                    becoming another unwcomele sayeft statistie yb conduncitg
                    detailed dropped obejct suvrey of hazardous areas.Tubestar
                    provides qualified nispeoctsr to conduct athorough dropped
                    object survey of het derick and other areas sa required. All
                    het arehacwihs earot be inspected aer divided under diferent
                    zones and aer surveyed sa per Clienst' Droped obejct pocily.
                    Allhte findings aer recordedni hte reportwhti photographic
                    evidence and corective acoitns ofrthe same.
                    Reportsidividedintowtoparts,onesitheInspectionSurveywhcihincludes
                    al observations and another is the Failed items list which
                    coversthe items which failed ni the survey.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Hull Structüral Thickness Gauging Survey
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - Bharat NDT is accredited withABS for performing thickness
                    gauging as part of special periodic. surveys for
                    mobileoffshoredrilling units. Our personnel have necessary
                    experienceni various aspects of special survey and
                    understand the special requirement survey ot deliver
                    accurate reportswithtables and CADdrawings.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Eddy Current Inspection
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    - More and more companies are opting ot go ni for Eddy
                    current inspection due to inherent benefit of
                    non-requirement of paint removal for inspection which is a
                    drawback ni the conventional NDT method of MPI. Eddy
                    currenteliminates the need of surface preparation and
                    saveslot of time ni paint removal and re-application of
                    paint post inspection. Only at places of indications
                    received, prove-uo si done with MPI. Tubestar has onboard
                    trained an qualified inspectors to carry out Eddy current
                    inspection onshore & offshore.Tubestar uses latest state of
                    the art Phasec-3D unit from GE for inspection.
                  </li>
                </ul>
              </div>
            </div>
            <p className="mb-4 text-sm font-bold text-gray-800 dark:text-gray-100">
              • Rig Audit & Inspection Division:
            </p>
            <div className="p-5 flex justify-between rounded mb-6 bg-gray-100 dark:bg-gray-700">
              <div className="w-3/5">
                <ul className="mb-5">
                  BharatNDT provides Third party inspection services for
                  inspection and auditing of onshore andof shore rigs.
                  Surveysare broadly categorized as:
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    Conditional survey - Toprovide accurate insight of the
                    equipment status andconditionand working systems
                  </li>
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    Fitfor Purpose AcceptanceSurvey- To confirm the
                    functionality of the rig, systems, policies and procedures
                  </li>
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    Upgrade and NewConstruction- To ensure that the rig upgrade
                    or new construction complies with al safety andoperations
                    standards
                  </li>
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    Compliance and Functionality Survey - To evaluate the
                    compliance of the rig equipment, procedures and policies
                    tothe International acceptable standards and toconform the
                    functionalityof the equipmentaccording to the standards and
                    design
                  </li>
                  <li className="mb-2 mt-2 text-xs text-gray-600 dark:text-gray-400">
                    Value Evaluationand Selection Survey- To evaluateand value a
                    rigfor the purpose ofeither sellingor purchasing as wel as
                    for banks/financial institutes to grantproject loans and
                    also to rank the rigs to create options tochoose or select
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export { Trainings };

import * as React from "react";
import { useRef, useEffect } from "react";
import { TimelineLite, TweenMax, Power3 } from "gsap";
import imgGirl from "./../assets/Shutterstock-a8dQhAHvG1.jpg";
import imgBoy from "./../assets/Shutterstock-48vwk1Ao2S.jpg";
import arrow from "./../assets/arrow-right.svg";
import { useHistory } from "react-router-dom";

export default function Hero() {
  let app = useRef(null);
  let images = useRef(null);
  let content = useRef(null);
  let tl = new TimelineLite({ delay: 0.8 });
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Images Vars
    const girlImage = images.firstElementChild; // or children[0]
    const boyImage = images.lastElementChild;

    //content vars
    const headlineFirst = content.children[0].children[0];
    const headlineSecond = headlineFirst.nextSibling;
    const headlineThird = headlineSecond.nextSibling;
    const contentP = content.children[1];
    const contentButton = content.children[2];

    //Remove initial flash
    TweenMax.to(app, 0, { css: { visibility: "visible" } });

    //Images Animation
    tl.from(girlImage, 1.2, { y: 1280, ease: Power3.easeOut }, "Start")
      .from(
        girlImage.firstElementChild,
        2,
        { scale: 1.6, ease: Power3.easeOut },
        0.2
      )
      .from(boyImage, 1.4, { y: 1280, ease: Power3.easeOut }, 0.2)
      .from(
        boyImage.firstElementChild,
        2,
        { scale: 1.6, ease: Power3.easeOut },
        0.2
      );

    //Content Animation
    tl.staggerFrom(
      [headlineFirst.children, headlineSecond.children, headlineThird.children],
      1,
      {
        y: 44,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "Start"
    )
      .from(contentP, 1, { y: 20, opacity: 0, ease: Power3.easeOut }, 1.4)
      .from(contentButton, 1, { y: 20, opacity: 0, ease: Power3.easeOut }, 1.6);
  }, [tl]);

  return (
    <div className="hero" ref={(el) => (app = el)}>
      <div className="container">
        <div className="hero-inner pt-36 xl:pt-48  flex flex-col md:flex-row">
          <div className="hero-content">
            <div className="hero-content-inner" ref={(el) => (content = el)}>
              <h1 className="font-styling">
                <div className="hero-content-line">
                  <div className="hero-content-line-inner">
                    Your comprehensive inspection
                  </div>
                </div>
                <div className="hero-content-line">
                  <div className="hero-content-line-inner">
                    and maintenance partner
                  </div>
                </div>
                <div className="hero-content-line">
                  <div className="hero-content-line-inner">
                  </div>
                </div>
              </h1>
              <p className="mobile-text py-10">
                Bharat-NDT offers comprehensive inspection services in India and
                neighboring countries. We specialize in inspection, repair,
                maintenance, and calibration. With strategically located bases,
                we serve offshore, marine, and petrochemical industries. Visit
                our website for details or contact us for inquiries and pricing.
                Your reliable inspection partner.
              </p>
              <div class="btn-center-container">
                <div className="btn-row">
                  <button className="explore-button" onClick={() => history.push("/contact-us")}>
                    Contact Us
                    <div className="arrow-icon">
                      <img src={arrow} alt="row" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-images ">
            <div ref={(el) => (images = el)} className="hero-images-inner">
              <div className="hero-image girl mobile-image">
                <img src={imgGirl} alt="girl" />
              </div>
              <div className="hero-image boy mobile-image">
                <img src={imgBoy} alt="boy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Hero };

import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
export const theme = extendTheme({ config });

export const chakraLightTheme = extendTheme({
  colors: {
    white: {
      100: "#FFFFFF",
    },
    black: {
      100: "#000000",
    },
    gray: {
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
    indigo: {
      900: "#3C366B",
      800: "#434190",
      700: "#4C51BF",
      600: "#5A67D8",
      500: "#667EEA",
      400: "#7F9CF5",
      300: "#C3DAFE",
      200: "#C3DAFE",
      100: "#EBF4FF",
    },
    green: {
      900: "#22543D",
      800: "#276749",
      700: "#2F855A",
      600: "#38A169",
      500: "#48BB78",
      400: "#68D391",
      300: "#9AE6B4",
      200: "#C6F6D5",
      100: "#F0FFF4",
    },
    orange: {
      900: "#7B341E",
      800: "#9C4221",
      700: "#C05621",
      600: "#DD6B20",
      500: "#ED8936",
      400: "#F6AD55",
      300: "#FBD38D",
      200: "#FEEBC8",
      100: "#FFFAF0",
    },
    red: {
      900: "#742A2A",
      800: "#9B2C2C",
      700: "#C53030",
      600: "#E53E3E",
      500: "#F56565",
      400: "#FC8181",
      300: "#FEB2B2",
      200: "#FED7D7",
      100: "#FFF5F5",
    },
  },
});

import * as React from "react";
import { useHistory } from "react-router-dom";
import bndt from "./../assets/temp.jpeg";

export default function Navbar() {
  const [sideBar, setsideBar] = React.useState();
  const [deliverables, setDeliverables] = React.useState(false);
  const history = useHistory();

  return (
    <div className="bg-gray-100">
      <div className="2xl:container 2xl:mx-auto">
        {/* Code block starts */}
        <nav className="2xl:container 2xl:mx-auto md:px-6 lg:px-36 mx-auto hidden md:block bg-white-100 shadow-md fixed top-0 left-0 z-20">
          <div className="justify-between container h-16 flex items-center lg:items-stretch mx-auto">
            <div className="flex items-center">
              <div className="mr-10 flex items-center">
                <img src={bndt} width={60} height={60} />
              </div>
              <ul className="pr-32 xl:flex hidden items-center h-full">
                <li className="cursor-pointer h-full flex items-center text-sm text-indigo-700 tracking-normal">
                  Home
                </li>
                <li
                  className="cursor-pointer mx-10 hover:text-indigo-700 transition duration-150 ease-in-out h-full flex items-center text-sm text-white tracking-normal relative"
                  onClick={() => setDeliverables(!deliverables)}
                >
                  {deliverables && (
                    <ul className="bg-white-100 shadow rounded py-1 w-48 left-0 mt-16 -ml-4 absolute  top-0">
                      <li 
                        onClick={() => history.push("/trainings")}
                        className="list-none cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-200 hover:text-white px-3 font-normal">
                        Services
                      </li>
                    </ul>
                  )}
                  Training & placement
                  <span className="ml-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-chevron-down"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </span>
                </li>
                <li
                  onClick={() => history.push("/about-us")}
                  className="cursor-pointer hover:text-indigo-700 transition duration-150 ease-in-out h-full flex items-center text-sm text-white mr-10 tracking-normal text-white hover:text-indigo-700"
                >
                  About Us
                </li>
                <li
                  onClick={() => history.push("/contact-us")}
                  className="cursor-pointer hover:text-indigo-700 transition duration-150 ease-in-out h-full flex items-center text-sm text-white mr-10 tracking-normal text-white hover:text-indigo-700"
                >
                  Contact Us
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="md:hidden">
          <div className="w-full shadow-md bg-gray-100 fixed top-0 z-40">
            <div className="flex sticky top-0 md:hidden mx-auto container">
              <div className="border-b py-4 border-gray-200 flex items-stretch mx-4 w-full justify-between">
                <div className="flex md:hidden items-center justify-end mr-2">
                  <div className="flex items-center">
                    <div className="h-full flex items-center">
                      <button
                        id="menu"
                        aria-label="open menu"
                        className="focus:outline-none rounded-md text-gray-800"
                        onClick={() => setsideBar(!sideBar)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                        >
                          <path
                            id="Path_1"
                            data-name="Path 1"
                            d="M4,6H20M4,12H20M4,18h7"
                            transform="translate(-3 -5)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <img src={bndt} width={40} height={40} />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              sideBar
                ? "fixed w-full h-full transform translate-x-0 z-40 transition duration-700 sm:hidden"
                : "fixed w-full h-full transform -translate-x-full z-40 transition duration-700 sm:hidden"
            }
            id="mobile-nav"
          >
            <div className="overflow-hidden w-full h-full shadow-lg z-40 fixed top-0 bg-white-100 flex-col justify-between xl:hidden pb-4 transition duration-500 ease-in-out">
              <div className="h-flex-1 overflow-hidden">
                <div className="flex flex-col justify-between h-full w-full overflow-hidden">
                  <div className="overflow-hidden">
                    <div className="mt-6 flex w-full items-center justify-between overflow-hidden">
                      <div className="flex items-center justify-between w-full px-4">
                        <div className="md:pr-10 lg:pr-0 h-full flex items-center w-full">
                          <div className="relative w-full">
                            <img src={bndt} width={40} height={40} />
                          </div>
                        </div>
                        <button
                          aria-label="close menu"
                          className="focus:outline-none rounded-md text-gray-800 pl-8"
                          onClick={() => setsideBar(!sideBar)}
                        >
                          <svg
                            width={14}
                            height={14}
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 1L1 13"
                              stroke="#1F2937"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L13 13"
                              stroke="#1F2937"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-row pt-6 overflow-hidden">
                      <div className="w-screen pt-20 h-screen flex-none bg-gray-100">
                        <div className="fixed top-24 pin-t w-full h-screen">
                          <div className="bg-white rounded w-full">
                            <div onClick={() => history.push("/")} className="p">
                              <a className="block no-underline font-semibold text-block focus:bg-orange-200 px-4 py-6">
                                Home
                              </a>
                            </div>
                            <div onClick={() => history.push("/trainings")}>
                              <a className="focus:bg-orange-400 block no-underline font-semibold text-block px-4 py-6">
                                Services
                              </a>
                            </div>
                            <div onClick={() => history.push("/about-us")}>
                              <a className=" focus:bg-orange-400 block no-underline font-semibold text-block px-4 py-6">
                                About Us
                              </a>
                            </div>
                            <div onClick={() => history.push("/contact-us")}>
                              <a className=" focus:bg-orange-400 block no-underline font-semibold text-block px-4 py-6 border-b">
                                Contact Us
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export { Navbar };
